import {
    Button,
    Card,
    RadioGroupField,
    Radio,
    Divider,
    Grid,
    SelectField,
    Text,
    useTheme,
    Flex
} from '@aws-amplify/ui-react';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmissionData, SubmissionHandler } from 'types';
import { yupResolver } from "@hookform/resolvers/yup"
import schema, { config } from 'helpers/FormSchema';
import FileUploader from 'components/StorageManagerComponent';
import Loader from 'components/Loader';
import LinkInputs from 'components/LinkInputs';
import { useEffect, useState } from 'react';

const SubmissionForm = (
    {
        onSubmit,
        isLoading,
        openResetModal,
        generating
    }: {
        onSubmit: SubmissionHandler,
        isLoading: boolean,
        openResetModal: () => void,
        generating: boolean
    },
) => {
    const { tokens } = useTheme();
    const { register, handleSubmit, formState, ...methods } = useForm<SubmissionData>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            contentType: 'ARTICLE',
            headingsNumber: 1,
            headingsType: 'h2',
            links: [],
            files: [],
        }
    });
    const [limitReached, setLimitReached] = useState<boolean>(false);
    const options = Array.from({ length: 20 }, (_, index) => index + 1);
    const linksLength = methods.watch('links')?.length ?? 0;
    const filesLength = methods.watch('files')?.length ?? 0;
    const privacyPolicyURL = new URL('/assets/privacyPolicy.pdf', import.meta.url).href;
    const cookiePolicyURL = new URL('/assets/cookiePolicy.pdf', import.meta.url).href;
    
    useEffect(() => {
        setLimitReached((linksLength ?? 0) + (filesLength ?? 0) >= config.MAX_SOURCES);
    }, [filesLength, linksLength]);

    return (
        <Card
            className='pb-7'
            position='relative'
            id='SubmissionForm'
            style={{
                flex: '1',
                borderInlineEnd: '1px solid #E0E0E0'
            }}
            height='inherit'
            color={tokens.colors.font.primary}
        >
            <FormProvider {...methods}
                register={register}
                formState={formState}
                handleSubmit={handleSubmit}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="h-full"
                >
                    <Flex
                        gap='16px'
                        className='px-8 py-6 overflow-y-auto'
                        direction='column'
                        height='calc(100% - 50px)'
                    >
                    <h4 className='font-bold'>
                        Seleziona la tipologia di contenuto da generare
                    </h4>
                        <RadioGroupField
                            fontSize='12px'
                            legendHidden
                            legend=''
                            defaultValue='ARTICLE'
                            name='content-type'
                        >
                            <Radio
                                value="WHITEPAPER"
                                {...register('contentType')}
                            >
                                Whitepaper
                            </Radio>
                            <Radio
                                value="ARTICLE"
                                {...register('contentType')}
                            >
                                Articolo
                            </Radio>
                        </RadioGroupField>
                        <Divider
                            orientation="horizontal"
                        />
                        <h4 className='font-bold'>Seleziona gli headings</h4>
                        <Grid
                            templateColumns="1fr 1fr"
                            gap='1rem'
                        >
                            <SelectField
                                label="Headings"
                                className='font-semibold'
                                textAlign="left"
                                {...register('headingsType')}
                            >
                                <option
                                    value="h2"
                                >
                                    H2
                                </option>
                                <option value="h3">H3</option>
                                <option value="h4">H4</option>
                            </SelectField>
                            <SelectField
                                label="Numero di Headings"
                                className='font-semibold'
                                textAlign="left"
                                defaultValue='1'
                                {...register('headingsNumber')}
                            >
                                {options.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </SelectField>
                        </Grid>
                        <Divider
                            orientation="horizontal"
                        />
                        <h4 className='font-bold'>Seleziona le fonti (massimo 10)</h4>
                        <Flex
                            direction='column'
                            gap='16px'
                        >
                            <Text
                                color={tokens.colors.neutral[10]}
                                textAlign='left'
                                className='small-text'
                            >
                                Link
                            </Text>
                            <LinkInputs
                                limitReached={limitReached}
                            />
                            <FileUploader
                                limitReached={limitReached}
                                links={linksLength}
                                files={filesLength}
                            />
                        </Flex>
                        <Flex
                            className='absolute bottom-14 right-0 left-0 px-8 justify-between'
                        >
                            <a
                                href='mailto:ai@digital360.it'
                                className='small-text text-left text-primary-blue'
                            >
                                Hai bisogno di supporto?
                            </a>
                            <span
                                className='small-text text-left text-primary-blue'
                            >
                                <a href={privacyPolicyURL} target="_blank">
                                    Privacy policy
                                </a> - <a href={cookiePolicyURL} target="_blank">
                                    Cookie policy
                                </a>
                            </span>
                        </Flex>
                    </Flex>
                    <Grid
                        position='absolute'
                        bottom='0'
                        width='100%'
                        className='buttons-grid'
                        templateColumns='3.5fr 2fr 3.5fr'
                        height='50px'
                        backgroundColor='#ffffff'
                        paddingInline='32px'
                        paddingBlock='8px'
                        justifyContent='center'
                    >
                        <Button
                            className='font-semibold self-center col-span-1 h-9 text-sm text-black border-0 bg-white'
                            onClick={openResetModal}
                            disabled={!(linksLength || filesLength) || generating}
                        >
                            Cancella tutto
                        </Button>
                        <div
                            className='bg-white'
                        >
                        </div>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Button
                                className={
                                    `generate-btn text-center col-span-1 h-9 text-sm
                                    ${formState.isValid && !generating ? 'btn--active' : 'btn--inactive'}`
                                }
                                type='submit'
                                disabled={!formState.isValid || (formState.isValid && generating)}
                            >
                                GENERA
                            </Button>
                        )}
                    </Grid>
                </form>
            </FormProvider>
        </Card>
    )
};

export default SubmissionForm;