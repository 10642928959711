import { ErrorProp } from "types";

// helpers/errorHelpers.js
export const getErrorMessage = (error: ErrorProp|null) => {
    let titleText = 'Si è verificato un errore';
    let message = 'Si è verificato un errore. Riprova più tardi.';
    if (null === error) {
        return { titleText, message };
    }
    switch (error.errorCode) {
        case 'generic':
            titleText = 'Si è verificato un errore';
            message = 'Si è verificato un errore. Riprova più tardi.';
            break;
        case 'input_too_long':
            titleText = 'Superata la dimensione massima in ingresso';
            message = 'I contenuti inseriti come fonti superano complessivamente la dimensione massima consentita.\nProva a rimuovere alcune delle fonti prima di procedere alla generazione del contenuto.';
            break;
        case 'extract_resource':
            titleText = 'Si è verificato un errore';
            message = 'Non è stato possibile processare le seguenti fonti:';
            break;
        case 'timeout':
            titleText = 'Si è verificato un errore';
            message = 'Mi dispiace, sembra che ci stia volendo più tempo del previsto. Ti invitiamo a riprovare o a modificare i documenti da elaborare.';
            break;
        default:
            titleText = 'Si è verificato un errore';
            message = 'Si è verificato un errore. Riprova più tardi.';
    }
    return { titleText, message };
};
