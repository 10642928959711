import { useEffect, useState } from 'react';
import { fetchAuthSession, decodeJWT } from 'aws-amplify/auth';
import * as Sentry from '@sentry/react';
import useWebSocket from 'react-use-websocket';

const usePromptWebSocket = () => {
    const [wsUrl, setWsUrl] = useState('');
    const [shouldConnect, setShouldConnect] = useState(false);
    const [user, setUser] = useState<string>('');

    const { sendJsonMessage, lastMessage } = useWebSocket(
        wsUrl,
        {
            shouldReconnect: () => true,
            reconnectInterval: 1000,
        },
        shouldConnect
    );

    useEffect(() => {
        const connectSocket = async () => {
            try{
                Sentry.addBreadcrumb({
                    category: 'app',
                    message: 'Connessione alla socket in corso, prendo token...',
                    level: 'info'
                });
                const token = `${(await fetchAuthSession()).tokens?.idToken}`;
                const email = decodeJWT(token)?.payload?.email;
                if ('string' === typeof (email)) {
                    setUser(email);
                }
                const url = `${APP_WEBSOCKET_URL}?token=${token}&key=${Math.random()}`;
                setWsUrl(url);
            } catch (err) {
                console.error('Si è verificato un errore: ', err);
                Sentry.captureException(err, {
                    tags: {
                        component: 'webSocketHook'
                    }
                });
            }
        };
        if ('' === wsUrl) {
            connectSocket();
        }
    }, [wsUrl, setUser]);

    useEffect(() => {
        const refreshSession = async () => {
            try {
                const session = await fetchAuthSession({ forceRefresh: true });
                const token = session.tokens?.idToken;
                const url = `${APP_WEBSOCKET_URL}?token=${token}&key=${Math.random()}`;
                setWsUrl(url);
            } catch (err) {
                console.error('Error durante il refresh della sessione: ', err);
                Sentry.captureException(err, {
                    tags: {
                        commponent: 'webSocketHook'
                    }
                });
            }
        };
        const intervalId = setInterval(refreshSession, 45 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    return {
        lastMessage,
        sendJsonMessage,
        setShouldConnect,
        user
    }
}

export default usePromptWebSocket;