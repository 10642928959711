import React from "react";
import FieldsArrayComponent from "../../../components/FieldsArrayComponent";
import { Button, Divider, Grid } from "@aws-amplify/ui-react";
import { HandleFieldsFuncs } from "types";

const HeadingsModal = ({
    modalRef,
    headings,
    headingsKey,
    headingsFuncs,
    onSubmit,
    onClose
}: {
    modalRef: React.RefObject<HTMLDialogElement>,
    headings: string[],
    headingsKey: string,
    headingsFuncs: HandleFieldsFuncs,
    onSubmit: () => void,
    onClose: () => void
}) => {
    return (
        <dialog
            ref={modalRef}
            id="headings_modal"
            className="modal p0"
        >
            <div
                className="modal-box"
            >
                <h3
                    className="font-bold my-2 text-2xl text-left pl-8"
                >
                    Gestisci gli Headings generati
                </h3>
                <Divider
                    color='#E0E0E0'
                    width='100%'
                    marginBottom='16px'
                ></Divider>
                <div
                    style={{
                        paddingInline: '32px'
                    }}
                >
                    <FieldsArrayComponent
                        fields={headings}
                        fieldsFuncs={headingsFuncs}
                        key={headingsKey}
                        editable
                    ></FieldsArrayComponent>
                </div>
                <Divider
                    width='100%'
                    marginTop='16px'
                ></Divider>
                <div className="modal-action m-0">
                    <form
                        method="dialog"
                        className="grow"
                    >
                        <Grid
                            paddingBlock='8px'
                            templateColumns='1fr 1fr 1fr'
                            paddingInline='32px'
                        >
                            <button
                                onClick={onClose}
                                className="btn-sm border-0 font-semibold"
                                style={{
                                    columnCount: '1',
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInline: 'auto',
                                    background: '#ffffff'
                                }}
                            >
                                Annulla
                            </button>
                            <div
                                style={{
                                    columnCount: '1'
                                }}
                            >
                            </div>
                            <Button
                                className="btn-sm rounded-none h-9 px-2 font-semibold text-sm text-white"
                                onClick={onSubmit}
                                backgroundImage='linear-gradient(to right, #008AC9, #1AA814)'
                            >
                                CONTINUA
                            </Button>
                        </Grid>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default HeadingsModal;