import { Button, Card, Flex, Grid, TextAreaField, TextField } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import CopyButton from "components/CopyToClipboardComponent";
import Editor from "components/Editor";
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "components/Loader";

const OutputForm = ({ socketMessage, connectionId }: {
    socketMessage: MessageEvent | null,
    connectionId: string
}) => {
    const [title, setTitle] = useState<string>('');
    const [summary, setSummary] = useState<string>('');
    const [article, setArticle] = useState<string>('');
    const [editedArticle, setEdited] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [plagiarism, setPlagiarism] = useState<string>('--');
    const [authentic, setAuthentic] = useState<string>('--');
    const [bodyCompleted, setBodyCompleted] = useState<boolean>(false);

    useEffect(
        () => {
            if (socketMessage && socketMessage.data) {
                try {
                    const data = JSON.parse(socketMessage.data);
                    if (!data.type) {
                        return;
                    }
                    switch (data.type) {
                        case 'title':
                            if (bodyCompleted) {
                                setTitle(prev => `${prev}${data.content}`);
                            }
                            break;
                        case 'summary':
                            if (bodyCompleted) {
                                setSummary(prev => `${prev}${data.content}`);
                            }
                            break
                        case 'body':
                            setArticle(prev => `${prev}${data.content}`);
                            if ('string' === typeof data.stop_reason && 'body' === data.type) {
                                setBodyCompleted(true);
                            }
                            break;
                    }
                    return;
                } catch(err) {
                    console.error('Non è stato possibile generare il contenuto.', err);
                    Sentry.captureException(err, (scope) => {
                        scope.setTag('component', 'OutputForm');
                        scope.setContext('Socket', {
                            rawPayload: socketMessage
                        })
                        return scope;
                    });
                }
            }
        },
        [bodyCompleted, socketMessage]
    );

    const requestAuthenticityCheck = useCallback(
        async () => {
            setIsLoading(true);
            if ('' !== editedArticle) {
                setArticle(editedArticle);
            }
            try {
                let textToCheck = '' !== editedArticle ? editedArticle : article;
                // Rimuovo i tag underline e inserisco uno spazio prima e dopo
                // ogni tag html per far funzionare correttamente l'algoritmo di
                // antiplagio.
                textToCheck = textToCheck.replace(/<\/?(h[2-4]|p)>/g, match => ` ${match} `);
                textToCheck = textToCheck.replace(/<\/?u>/g, '');
                const response = await fetch(
                APP_ENDPOINT_API + 'ngramCheck',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        connectionId: connectionId,
                        generatedText: textToCheck
                    })
                }
            );
                const decodedResp = await response.json();
                const plagiarism = Math.ceil(decodedResp.percentage_plagiarism);
                const authentic = 100 - plagiarism;
                setAuthentic(authentic.toString() + '%');
                setPlagiarism(plagiarism.toString() + '%');

                const plagStrings = decodedResp.plagiarized_text;
                let splitContent = textToCheck.split(' ');
                // Rimuovo gli elementi che consistono solo in stringa vuota.
                splitContent = splitContent.filter(str => str.length > 0);

                for (let i = plagStrings.length - 1; i >= 0; i--) {
                    const end = plagStrings[i].end;
                    const start = plagStrings[i].start;
                    splitContent.splice(end + 1, 0, '</u>');
                    splitContent.splice(start, 0, '<u>');
                }
                let formattedContent = splitContent.join(' ');
                // Rimuovo gli spazi addizionali inseriti in seguito al join.
                formattedContent = formattedContent.replace(/\s<u>\s/g, ' <u>');
                formattedContent = formattedContent.replace(/\s<\/u>\s/g, '</u> ');
                setArticle(formattedContent);
            } catch (err) {
                console.error(err);
            }
            setIsLoading(false);
        },
        [article, connectionId, editedArticle]
    )

    return(
        <Card
            className="overflow-y-auto"
            id='OutputForm'
            textAlign='left'
            paddingInline='32px'
            paddingBlockStart='24px'
            position='relative'
            height='calc(100% - 50px)'
        >
            <Grid
                maxHeight='100%'
                templateRows='1fr 1.5fr'
                height='100%'
                gap='16px'
            >
                <Flex
                    height='100%'
                    direction='column'
                    gap='16px'
                >
                    <Flex
                        className="justify-between"
                    >
                        <h4
                            className="mb-10 font-bold text-black"
                        >
                            Contenuto generato
                        </h4>
                        <Grid
                            className={`grid-cols-[102px_102px] grid-rows-[36px_20px] gap-y-1 ${bodyCompleted ? '' : 'hidden'}`}
                        >
                            {isLoading ? (
                                <div
                                    className="col-span-2 text-right"
                                >
                                    <Loader />
                                </div>
                            ) : (
                                <Button
                                    className="col-span-2 h-9 text-sm font-semibold border-primary-blue text-primary-blue"
                                    onClick={requestAuthenticityCheck}
                                >
                                    Verifica autenticità (ITA/ITA)
                                </Button>
                            )}
                            <p
                                className="text-sm font-bold text-[#FB6767]"
                            >
                                <span>{plagiarism}</span> Copia
                            </p>
                            <p
                                className="text-sm font-bold text-[#5ABE4F]"
                            >
                                <span>{authentic}</span> Inedito
                            </p>
                        </Grid>
                    </Flex>
                    <TextField
                        value={title}
                        className="small-text"
                        label={
                            <Flex
                                justifyContent='space-between'
                            >
                                <p>Titolo</p>
                                <CopyButton
                                    text={title}
                                ></CopyButton>
                            </Flex>
                        }
                        onChange={(e) => (setTitle(e.target.value))}
                    ></TextField>
                    <TextAreaField
                        value={summary}
                        className="small-text"
                        flex='0 1 auto'
                        label={
                            <Flex
                                justifyContent='space-between'
                            >
                                <p>Sommario</p>
                                <CopyButton
                                    text={summary}
                                ></CopyButton>
                            </Flex>
                        }
                        onChange={(e) => (setSummary(e.target.value))}
                        style={{ height: '100%' }}
                    >
                    </TextAreaField>
                </Flex>
                <Editor
                    content={article}
                    editContent={setEdited}
                />
            </Grid>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={true}
                theme="dark"
                closeOnClick
            />
        </Card>
    )
};

export default OutputForm